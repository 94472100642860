import StoryblokClient from 'storyblok-js-client';
import { StoryblokService } from '../storyblok';

export interface Language {
  locale: string;
  label?: string;
}

export const LanguageService = {
  defaultLocale: 'en',
  storyblokClient: new StoryblokClient({
    accessToken: StoryblokService.getConfig().options.accessToken as string,
  }),
  async getLanguages(): Promise<Language[]> {
    const { data } = await this.storyblokClient.get('cdn/spaces/me');
    const languages = data?.space?.language_codes?.map((el) => ({ locale: el }));
    return [{ locale: 'default', label: this.defaultLocale }, ...languages];
  },
  /**
   * NOTE:
   * When invoking in a server side rendering context, please pass locale as an argument!
   * This prevent a fatal exceptions, as `document`isn't defined in this context.
   */
  isPageDefaultLanguage(locale?: string): boolean {
    return this.defaultLocale === (locale || document.documentElement.lang);
  },
  getActiveLanguage(): string {
    return document.documentElement.lang;
  },
  getLocaleAwareLink(path: string, locale: string): string {
    if (!path) {
      return '';
    }

    if (!this.isPageDefaultLanguage(locale)) {
      return `/${locale}/${path}`;
    }

    return `/${path}`;
  },
};
