import { contentEntries } from './content-entries';
import { forms } from './forms';
import { gridComponents } from './grid';
import { Navigation } from './navigation';
import { responsiveTable } from './responsive-table';
import { RocheGlobalConfig } from './global-config';
import { RocheRichtext } from './richtext';
import { RocheStageArticle } from './roche-stage-article';
import { RocheStageHome } from './roche-stage-home';
import { RocheTeaser } from './roche-teaser';
import { RocheTeaserAuto } from './roche-teaser-auto';
import { RocheTeaserExternal } from './roche-teaser-external';
import { RocheTeaserHighlight } from './roche-teaser-highlight';
import { RocheTeaserHighlightExternal } from './roche-teaser-highlight-external';
import { RocheTeaserMosaic } from './roche-teaser-mosaic';
import { RocheTeaserSlider } from './roche-teaser-slider';
import { RocheTeaserWall } from './roche-teaser-wall';
import { RocheTriggerAndModal } from './roche-trigger-and-modal';

// eslint-disable-next-line import/no-default-export
export default {
  ...contentEntries,
  ...forms,
  ...gridComponents,
  ...responsiveTable,
  Navigation,
  RocheGlobalConfig,
  RocheRichtext,
  RocheStageArticle,
  RocheStageHome,
  RocheTeaser,
  RocheTeaserAuto,
  RocheTeaserExternal,
  RocheTeaserHighlight,
  RocheTeaserHighlightExternal,
  RocheTeaserMosaic,
  RocheTeaserSlider,
  RocheTeaserWall,
  RocheTriggerAndModal,
};
