import React, { useEffect, useState } from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { SbEditableContent } from 'storyblok-react';
import { TeaserService, ArticleItem, ArticleparticipateItem } from '../../services';
import { blokToComponent } from '../helpers';
import { Props } from '../types';

const TeaserStageArticle = 'roche-stage-article' as React.ReactType;
const EventDetails = 'roche-event-details' as React.ReactType;
const Share = 'roche-share' as React.ReactType;

export const RocheStageArticle = ({
  blok, storyId, getComponent,
}: Props): JSX.Element => {
  const [stage, setStage] = useState<ArticleItem | ArticleparticipateItem>({} as ArticleItem);

  useEffect(() => {
    if (!storyId) {
      return;
    }
    TeaserService.getTeaser(storyId as number)
      .then((result) => {
        setStage(result);
      });
  }, []);

  const {
    copy,
    slotted_media: images,
    title,
  } = blok;

  const locationMapLink = stage.content?.location_map_link;

  return (
    <TeaserStageArticle
      headline={title || stage.content?.teaser_title}
      copy={copy || stage.content?.teaser_description}
      tag={stage.tag_list ? stage.tag_list[stage.tag_list.length - 1] : ''}
      subcategory={stage.content?.sub_category}
    >
      <EventDetails
        event-location={stage.content?.event_location}
        event-city={stage.content?.event_city}
        event-date={stage.content?.event_date}
        location-map-link-label={locationMapLink ? locationMapLink[0]?.text : ''}
        location-map-link={locationMapLink ? locationMapLink[0]?.link?.url : ''}
        custom-field-label={stage.content?.custom_field_label}
        slot="event-details"
      >
        <Share slot="share"></Share>

        {stage.content?.slotted_custom_content?.map(
          (content: StoryblokComponent<string> & SbEditableContent) => blokToComponent({
            blok: {
              ...content,
              slot: 'custom-content',
            },
            getComponent,
          }, 'richtext')
        )}

        {stage.content?.slotted_event_registration_link?.map(
          (content: StoryblokComponent<string> & SbEditableContent) => blokToComponent({
            blok: {
              ...content,
            },
            slot: 'event-registration-link',
            getComponent,
          })
        )}
      </EventDetails>

      {images.map((image) => blokToComponent({
        blok: {
          ...image, slot: 'media', fullscreen: true, ratio: '16:9',
        },
        getComponent,
      }, 'image'))}
    </TeaserStageArticle>
  );
};
