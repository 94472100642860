import React from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { blokToComponent, filterProps } from '../helpers';
import { Props } from '../types';

export const Navigation = ({ blok, getComponent }: Props): JSX.Element => {
  const { slotted } = filterProps(blok);

  const children = Object.keys(slotted)
    .reduce((acc, key) => {
      const slottedComponents = (slotted[key] as StoryblokComponent<string>[])
        .map((component) => ({ ...component, slot: key, type: 'navigation' }));

      return [...acc, ...slottedComponents];
    }, []);

  return <>{children.map((child, index) => blokToComponent({ blok: child, getComponent }, `${index}`))}</>;
};
