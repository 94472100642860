import React, { useEffect, useState } from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { TeaserService, ArticleItem, ArticleparticipateItem } from '../../services';
import { blokToComponent, filterProps } from '../helpers';
import { parseAssetSource } from '../props/asset';
import { Props } from '../types';

const TeaserHighlight = 'roche-teaser-highlight' as React.ReactType;

export const RocheTeaserHighlight = ({
  blok, keyForChildren, updateHoistedState, getComponent,
}: Props): JSX.Element => {
  const [teaser, setTeaser] = useState<ArticleItem | ArticleparticipateItem>({} as ArticleItem);

  useEffect(() => {
    // in case it's fetched from teaser mosaic or as part of an auto list
    if (blok.teaser && !blok.teaser.article) {
      const {
        page,
        sorting,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sub_category,
        // eslint-disable-next-line @typescript-eslint/camelcase
        teaser_number,
      } = blok?.teaser;

      // eslint-disable-next-line @typescript-eslint/camelcase
      if (teaser_number <= 0) {
        return;
      }

      TeaserService.getTeasers(sub_category, sorting, page, teaser_number)
        .then((result) => {
          setTeaser({ ...blok, ...result.items[0] });
          // eslint-disable-next-line no-underscore-dangle
          return updateHoistedState?.(1, 1, blok._uid, { ...blok, ...result.items[0] });
        });
    }

    if (!blok.article?.story?.id && !blok.teaser?.article?.story?.id) {
      return;
    }

    TeaserService.getTeaser(blok.article?.story.id || blok.teaser?.article?.story.id)
      .then((result) => {
        setTeaser(result);
        // eslint-disable-next-line no-underscore-dangle
        return updateHoistedState?.(1, 1, blok._uid, result);
      });
  }, []);

  if (!teaser.content) {
    return null;
  }

  const slottedImage = blok.slotted_image_highlight?.[0];
  const { slotted } = filterProps(blok);
  const children = Object.keys(slotted)
    .reduce((acc, key) => {
      const slottedComponents = (slotted[key] as StoryblokComponent<string>[])
        .map((component) => ({ ...component, slot: key }));

      return [...acc, ...slottedComponents];
    }, []);

  return (
    <TeaserHighlight
      article-page-url={`/${blok.article?.story?.url}`}
      key={keyForChildren}
      layout={blok.layout}
      mirror-layout={blok.mirror_layout}
      image-animation-name={blok.image_animation_name}
      content-animation-name={blok.content_animation_name}
      teaser-highlight-image={parseAssetSource(slottedImage?.src.filename)
        || slottedImage?.brightcove_id}
      subcategory={teaser.content?.sub_category}
      tag={teaser.tag_list ? teaser.tag_list[teaser.tag_list.length - 1] : ''}
      teaser-videoloop={teaser.content?.teaser_image[0]?.brightcove_id}
      teaser-image={parseAssetSource(teaser.content?.teaser_image[0]?.src.filename)}
      teaser-title={teaser.content?.teaser_title}
      teaser-description={teaser.content?.teaser_description}
      loaded={teaser.content !== undefined}
      slot={blok.slot}
    >
      {children.map((child, index) => blokToComponent({ blok: child, getComponent }, `${index}`))}
    </TeaserHighlight>
  );
};
