import React, { useState } from 'react';
import { SbEditableContent } from 'storyblok-react';
import { Props } from '../types';
import { blokToComponent } from '../helpers';
import { MathService } from '../../services';

enum SlidesPerPage {
  minimum = 1,
  maximum = 3,
}

export interface TeaserSlider extends SbEditableContent {
  teasers: SbEditableContent[];
  teasers_per_slider_page: number;
}
const RocheSlider = 'roche-slider' as React.ReactType;

const manageTeaserLayoutBasedOnSlidesPerPage = (slidesPerPage, teasers): SbEditableContent[] => {
  if (slidesPerPage > 1) {
    return teasers.map((item) => ({
      ...item,
      layout: 'teaser--image-on-top',
    }));
  }

  return teasers.map((item) => ({
    ...item,
    layout: item.layout !== '' && !(item.layout?.includes('top')) ? item.layout : 'teaser--image-on-right',
  }));
};

export const RocheTeaserSlider = ({ blok, getComponent }: Props): JSX.Element => {
  const [childReadyCount, setChildReadyCount] = useState(0);
  const { teasers_per_slider_page: teasersPerSliderPage, teasers, slot } = blok as TeaserSlider;
  const parsedTeasers = manageTeaserLayoutBasedOnSlidesPerPage(teasersPerSliderPage, teasers);
  const slidesPerPage = MathService
    .clamp(Number(teasersPerSliderPage), SlidesPerPage.minimum, SlidesPerPage.maximum);

  return (
    <RocheSlider
      disable-auto-init={childReadyCount < teasers.length}
      in-app-with-virtualised-dom={true}
      loop={false}
      slides-per-page={slidesPerPage}
      transition="by-page"
      slot={slot}
    >
      <div>
        {parsedTeasers.map((teaser, index) => blokToComponent({
          blok: teaser,
          getComponent,
          updateHoistedState: () => setChildReadyCount((prevState) => prevState + 1),
          wrapperTag: 'roche-slider-item',
          keyForChildren: index,
        }, 'slide'))}
      </div>
    </RocheSlider>
  );
};
