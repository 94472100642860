import { LanguageService } from '../language';
import { StoryblokService } from '../storyblok';

interface Query {
  query: string;
}

interface LocationMapLink {
  text: string;
  link: {
    url: string;
  };
}
interface TeaserContent {
  custom_field_label: string;
  event_date: string;
  event_location: string;
  event_city: string;
  location_map_link: LocationMapLink[];
  slotted_custom_content: [];
  slotted_event_registration_link: [];
  sub_category: string;
  teaser_description?: string;
  teaser_image: {
    src: {
      filename: string;
    };
  };
  teaser_title: string;
}
export interface ArticleItem {
  full_slug: string;
  tag_list: string[];
  content: TeaserContent;
  default_full_slug?: string;
}

export interface ArticleItems {
  items: ArticleItem[];
  total: number;
}

export interface ArticleparticipateItem extends ArticleItem {
  content: TeaserContent & {
    custom_field_label: string;
    event_date: string;
    event_location: string;
    event_city: string;
    location_map_link: LocationMapLink[];
    slotted_custom_content: [];
    slotted_event_registration_link: [];
  };
}

export interface ArticleparticipateItems {
  items: ArticleparticipateItem[];
  total: number;
}

interface TeaserQueryResult {
  data: {
    ArticleItem?: ArticleItem | null;
    ArticleparticipateItem?: ArticleparticipateItem | null;
    ArticleItems?: ArticleItems;
    ArticleparticipateItems?: ArticleparticipateItems;
  };
}

type ParsedQueryResult = ArticleItem
| ArticleparticipateItem
| ArticleItems
| ArticleparticipateItems;

export const TeaserService = {
  settings: {
    ...StoryblokService.getConfig(),
  },
  nonDefaultThemeSubcategories: ['events', 'life-talks'],
  async getTeaser(teaserId: number): Promise<ArticleItem | ArticleparticipateItem> {
    const teaser = await fetch(
      this.settings.options.url,
      this.createRequest(this.createTeaserByIdQuery(teaserId)),
    )
      .then((response) => response.json())
      .then((result) => this.parseQueryResults(result));

    return teaser;
  },
  async getTeasers(
    subCategory: string,
    sortBy: string,
    page: number,
    perPage: number,
    tag: string = null,
  ): Promise<ArticleItems | ArticleparticipateItems> {
    const teaser = await fetch(
      this.settings.options.url,
      this.createRequest(this.createTeaserByTypeQuery(subCategory, sortBy, page, perPage, tag)),
    )
      .then((response) => response.json())
      .then((result) => this.parseQueryResults(result));

    return teaser;
  },
  parseQueryResults(queryResult: TeaserQueryResult): ParsedQueryResult {
    const {
      ArticleItem, ArticleparticipateItem, ArticleItems, ArticleparticipateItems,
    } = queryResult.data;

    if (!ArticleItems || !ArticleparticipateItems) {
      return {
        ...(ArticleItem || null),
        ...(ArticleparticipateItem || null),
      };
    }

    return {
      items: [...ArticleItems.items, ...ArticleparticipateItems.items],
      total: ArticleItems.total + ArticleparticipateItems.total,
    };
  },
  createRequestHeaders(): HeadersInit {
    const { options: { accessToken } } = this.settings;

    return {
      token: accessToken as string,
      version: 'published',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  },
  createRequest(query: { query: string }): RequestInit {
    return {
      method: 'POST',
      mode: 'cors',
      headers: this.createRequestHeaders(),
      body: JSON.stringify(query),
    };
  },
  createLanguageQueryParameter(): string {
    return LanguageService.isPageDefaultLanguage() ? '' : `language: "${LanguageService.getActiveLanguage()}"`;
  },
  createTeaserByTypeQuery(
    subCategory: string,
    sortBy: string,
    page: number,
    perPage: number,
    tag: string = null,
  ): Query {
    const tagTeaserParams = `with_tag: "${tag || ' '}", page: ${page}, per_page: ${perPage}, ${this.createLanguageQueryParameter()}`;
    const subcategoryTeaserParams = `filter_query_v2: {sub_category: {in: "${subCategory}"}}, sort_by: "${sortBy}", page: ${page}, per_page: ${perPage}, ${this.createLanguageQueryParameter()}`;

    const parameters = tag ? tagTeaserParams : subcategoryTeaserParams;

    return {
      query: `{
        ArticleItems(${parameters}) {
          items {
            content {
              sub_category
              teaser_description
              teaser_image
              teaser_title
            }
            published_at
            default_full_slug
            tag_list
            id
            first_published_at
          }
          total
        }
        ArticleparticipateItems(${parameters}) {
          items {
            content {
              custom_field_label
              event_date
              event_location
              event_city
              location_map_link
              slotted_custom_content
              slotted_event_registration_link
              sub_category
              teaser_description
              teaser_image
              teaser_title
            }
            published_at
            default_full_slug
            id
            first_published_at
            tag_list
          }
          total
        }
      }`,
    };
  },
  createTeaserByIdQuery(teaserId: number): Query {
    const parameters = `id: ${teaserId}, ${this.createLanguageQueryParameter()}`;
    return {
      query: `{
      ArticleparticipateItem(${parameters}) {
        full_slug
        tag_list
        content {
          custom_field_label
          event_date
          event_location
          event_city
          location_map_link
          slotted_custom_content
          slotted_event_registration_link
          sub_category
          teaser_description
          teaser_image
          teaser_title
        }
      }
      ArticleItem(${parameters}) {
        full_slug
        tag_list
        content {
          sub_category
          teaser_description
          teaser_image
          teaser_title
        }
      }
    }`,
    };
  },
};
