import React, { useEffect, useState } from 'react';
import {
  ArticleItem,
  ArticleItems,
  ArticleparticipateItem,
  ArticleparticipateItems,
  LanguageService,
  TeaserService,
} from '../../services';
import { ConditionalWrapper } from '../helpers';
import { parseAssetSource } from '../props/asset';
import { Props } from '../types';

interface RocheTeaserAutoState {
  teasers: ArticleItems | ArticleparticipateItems;
}

const Teaser = 'roche-teaser' as React.ReactType;
const initialState: RocheTeaserAutoState = {
  teasers: {} as ArticleItems,
};

/* eslint-disable @typescript-eslint/camelcase */
export const RocheTeaserAuto = ({
  blok,
  updateHoistedState,
  slot,
  wrapperTag,
}: Props): JSX.Element => {
  const [state, setTeasers] = useState<RocheTeaserAutoState>(initialState);
  const Wrapper = wrapperTag as React.ReactType;

  useEffect(() => {
    const {
      sorting,
      sub_category,
      teaser_number,
      tag,
    } = blok;

    if (!sorting || (!sub_category && !tag) || !teaser_number) {
      return;
    }

    TeaserService.getTeasers(sub_category, sorting, (blok.page || 1), teaser_number, tag)
      .then((result) => {
        setTeasers({ teasers: result });
        // eslint-disable-next-line no-underscore-dangle
        return updateHoistedState?.(result.items.length, result.total, blok.sub_category, result);
      });
  }, []);

  if (Object.keys(state.teasers).length <= 0) { return null; }

  const language = LanguageService.isPageDefaultLanguage() ? '' : `/${LanguageService.getActiveLanguage()}`;

  return <>
    {state.teasers
      .items
      .map((teaser: ArticleItem | ArticleparticipateItem, index: number) => (
        <ConditionalWrapper
          key={index}
          condition={wrapperTag !== undefined && wrapperTag !== ''}
          wrapper={(children: JSX.Element): JSX.Element => (
            <Wrapper slot={slot}>{children}</Wrapper>
          )}
        >
          <Teaser
            article-page-url={`${language}/${teaser.default_full_slug}`}
            key={index}
            layout={blok.layout}
            subcategory={teaser.content?.sub_category}
            tag={teaser.tag_list ? teaser.tag_list[teaser.tag_list.length - 1] : ''}
            teaser-image={parseAssetSource(teaser.content?.teaser_image[0]?.src.filename)}
            teaser-title={teaser.content?.teaser_title}
            teaser-description={teaser.content?.teaser_description}
            event-date={teaser.content?.event_date}
            event-location={teaser.content?.event_location}
            event-city={teaser.content?.event_city}
            loaded={teaser.content !== undefined}
            slot={slot}
          >
          </Teaser>
        </ConditionalWrapper>
      ))}
  </>;
};
