import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { blokToComponent } from '../helpers';
import { Props } from '../types';

// build time (node context) does not have document defined.
const modal = typeof document !== 'undefined' ? document.getElementById('global-modal') : null;

export const RocheTriggerAndModal = ({ blok, slot, getComponent }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { modal_content: modalContent, modal_trigger: modalTrigger } = blok;
  // eslint-disable-next-line no-underscore-dangle
  const triggerIds = modalTrigger?.map((node) => node._uid);
  const handleModalTrigger = (event: CustomEvent): void => {
    if (!triggerIds?.includes(event.detail)) {
      return;
    }

    setIsOpen(true);
  };

  const handleModalClose = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('rocheModalTrigger', handleModalTrigger);
    window.addEventListener('rocheModalClose', handleModalClose);

    return function cleanup(): void {
      window.removeEventListener('rocheModalTrigger', handleModalTrigger);
      window.removeEventListener('rocheModalClose', handleModalClose);
    };
  }, []);

  const modalContentAsComponents = modalContent
    ? modalContent.map((element) => (
      blokToComponent({ blok: element, getComponent, slot: element.slotted }, 'modalContent')
    ))
    : null;

  const modalTriggersAsComponents = modalTrigger
    ? modalTrigger
      .map((element) => blokToComponent({
        blok: {
          ...element,
          'is-modal-trigger': true,
          'is-search-trigger': false,
        },
        slot,
        getComponent,
      }, 'modalTrigger'))
    : null;

  return (
    <>
      {modalTriggersAsComponents}
      {isOpen && ReactDOM.createPortal(modalContentAsComponents, modal)}
    </>
  );
};
