/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { SbEditableContent } from 'storyblok-react';
import { Props } from '../types';
import { blokToComponent } from '../helpers';

const StageHome = 'roche-stage-home' as React.ReactType;
const RocheSlider = 'roche-slider' as React.ReactType;

export const RocheStageHome = ({ blok, getComponent }: Props): JSX.Element => {
  const { media, items } = blok;
  return (
    <StageHome full-width={blok.full_width}>
      {media.map((medium: SbEditableContent) => blokToComponent({ blok: medium, getComponent, slot: 'media' }, 'media'))}
      <RocheSlider
        slot="slider"
        navigation-white
        navigation-align-inside-slide-bottom
        navigation-align-center-right
        fullscreen
        animation-duration="500"
        animation="crossfade"
        disable-auto-init={items.length <= 1}
        auto-play-interval="4"
      >
        {items.map((item: SbEditableContent, index: number) => blokToComponent({ blok: item, getComponent }, `roche-stage-item-${index}`))}
      </RocheSlider>
    </StageHome>
  );
};
