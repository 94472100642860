import React from 'react';
import { StoryblokComponent } from 'storyblok-js-client';
import { blokToComponent, filterProps } from '../helpers';
import { parseAssetSource } from '../props/asset';
import { Props } from '../types';

const TeaserHighlight = 'roche-teaser-highlight' as React.ReactType;

export const RocheTeaserHighlightExternal = ({
  blok, slot, getComponent,
}: Props): JSX.Element => {
  const slottedImage = blok.slotted_image_highlight?.[0];
  const { slotted } = filterProps(blok);
  const children = Object.keys(slotted)
    .reduce((acc, key) => {
      const slottedComponents = (slotted[key] as StoryblokComponent<string>[])
        .map((component) => ({ ...component, slot: key }));

      return [...acc, ...slottedComponents];
    }, []);

  return (
    <TeaserHighlight
      article-page-url={blok.url}
      layout={blok.layout}
      mirror-layout={blok.mirror_layout}
      image-animation-name={blok.image_animation_name}
      content-animation-name={blok.content_animation_name}
      teaser-title={blok.teaser_title}
      teaser-description={blok.teaser_description}
      teaser-highlight-image={parseAssetSource(slottedImage?.src.filename)
        || slottedImage?.brightcove_id}
      slot={slot}
    >
      {children.map((child, index) => blokToComponent({ blok: child, getComponent }, `${index}`))}
    </TeaserHighlight>
  );
};
