import { StoryData } from 'storyblok-js-client';
import { LanguageService } from '../language';
import { StringService } from '../string';
import { TeaserService } from '../teaser';

export interface GlobalConfigProps {
  baseDomain: string;
  brightcoveAccountId: string;
  brightcovePlayerId: string;
  cludoCustomerID: string;
  cludoEngineId: string | string[];
  cognitoUserpoolClientId: string;
  cognitoUserpoolId: string;
  locale: string;
  nonDefaultThemeSubcategories: string[];
  pageId: string;
  recaptchaKey: string;
  translationUrl: string;
  twitterHandle: string;
  whiteListedDomains: string;
  hubPages: string;
  tagPageUrl?: string;
  isShareHidden: string;
  isSearchActive: boolean;
  componentLibraryUrl: string;
}

type HTMLElementContent = string | { toString: () => string };
interface StoryDataWithLang extends StoryData {
  lang: string;
}

/**
 * We support both Storyblok APIs.
 * "full_slug" for GraphQL responses, cached_url for REST responses
 */
const getTagPageUrl = (
  settings: StoryData,
): string => settings?.content?.tag_page?.story?.full_slug || 'topic';

const getHubPages = (settings: StoryData, locale: string): string => {
  const links: Record<string, string> = (settings?.content?.hub_page_urls || [])
    .reduce(
      (object, link) => ({
        ...object,
        [`${link?.subcategory}`]: LanguageService.getLocaleAwareLink(link?.target_page?.cached_url, locale),
      }),
      {},
    );

  return JSON.stringify(links);
};

const pageTypes: Record<string, string> = {
  article: 'Explore',
  'article-participate': 'Participate',
  page: 'Page',
};

export const DomService = {
  createElement(tagName: string, content: HTMLElementContent = '', props: Record<string, string> = {}): HTMLElement {
    const element = document.createElement(tagName);
    element.innerHTML = typeof content === 'string' ? content : content.toString();
    Object.keys(props).forEach((prop) => {
      element.setAttribute(prop, props[prop]);
    });
    return element;
  },

  activateConsentScript(): void {
    const oneTrustScriptPlaceholder = document.getElementById('oneTrustScriptPlaceholder');

    if (oneTrustScriptPlaceholder) {
      const activatedOneTrustScript = document.createElement('script');
      const oneTrustScriptSettings = {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        type: 'text/javascript',
        charSet: 'UTF-8',
        async: 'true',
        defer: 'true',
        'data-domain-script': `${process.env.GATSBY_ROCHE_ONETRUST_KEY}`,
        'data-document-language': 'true',
      };

      Object.entries(oneTrustScriptSettings).forEach(([attrName, attrValue]) => {
        activatedOneTrustScript.setAttribute(attrName, attrValue);
      });

      document.head.replaceChild(activatedOneTrustScript, oneTrustScriptPlaceholder);
    }
  },
  getParsedLocale(locale: string): string {
    return locale === 'default' ? LanguageService.defaultLocale : locale;
  },

  getGlobalConfig(story: StoryData, settings: StoryData): GlobalConfigProps {
    const { uuid: pageId, lang: locale, content } = story as StoryDataWithLang;
    const parsedLocale = this.getParsedLocale(locale);
    const tagPage = getTagPageUrl(settings);

    return {
      ...(!tagPage
        ? {}
        : {
          getTagPageUrl: LanguageService.getLocaleAwareLink(getTagPageUrl(settings), parsedLocale),
        }),
      baseDomain: process.env.GATSBY_BASE_DOMAIN,
      brightcoveAccountId: process.env.GATSBY_BRIGHTCOVE_ACCOUNT_ID,
      brightcovePlayerId: process.env.GATSBY_BRIGHTCOVE_PLAYER_ID,
      cludoCustomerID: process.env.GATSBY_CLUDO_CUSTOMER_ID,
      cludoEngineId: (process.env.GATSBY_CLUDO_ENGINE_ID_LIST)
        .replace(/[\s]/g, '')
        .split(',')
        .filter((item) => item.indexOf(`${parsedLocale}:`) === 0)
        .join()
        .substring(parsedLocale.length + 1, 8),
      cognitoUserpoolClientId: process.env.GATSBY_COGNITO_USERPOOL_CLIENT_ID,
      cognitoUserpoolId: process.env.GATSBY_COGNITO_USERPOOL_ID,
      locale,
      nonDefaultThemeSubcategories: TeaserService.nonDefaultThemeSubcategories,
      pageId: `storyblok:${process.env.GATSBY_STORYBLOK_SPACE_API_KEY_NAME}:${pageId}`,
      recaptchaKey: process.env.GATSBY_GOOGLE_RECAPTCHA_KEY,
      translationUrl: `${process.env.GATSBY_ROCHE_COMPONENTS_LIBRARY_URL}/roche-component-library/assets/translations/${parsedLocale}.json`,
      componentLibraryUrl: `${process.env.GATSBY_ROCHE_COMPONENTS_LIBRARY_URL}/roche-component-library/assets`,
      twitterHandle: process.env.GATSBY_TWITTER_HANDLE,
      whiteListedDomains: process.env.GATSBY_WHITE_LISTED_DOMAINS,
      hubPages: getHubPages(settings, parsedLocale),
      isShareHidden: content.hide_share || false,
      isSearchActive: false,
    };
  },

  getPageCategory(componentName: string): string {
    return pageTypes[componentName];
  },

  getPageType(slug: string, subCategory: string, hubPagesJson: string, locale: string): string {
    const parsedLocale = this.getParsedLocale(locale);
    const url = LanguageService.isPageDefaultLanguage(parsedLocale) ? slug : `/${slug}`;
    const hubPages = StringService.stringToJavascript(hubPagesJson, {});

    // TODO: update home page detection once it's changed in gatsby-node
    if (slug.includes('home')) {
      return 'Home';
    }

    if (subCategory) {
      return 'Article';
    }

    if (Object.values(hubPages).includes(url)) {
      return 'Hub';
    }

    return 'Other';
  },
};
