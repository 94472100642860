import React, { useEffect, useState } from 'react';
import { StringService } from '@rocheglobal/component-library-125/src/services/string/index';
import { TranslationService } from '@rocheglobal/component-library-125/src/services/translation/index';
import { Default } from '../default';
import { blokToComponent, filterProps, slottedToComponents } from '../helpers';
import { AnyProps } from '../types';

interface NewsletterFormState {
  translations?: {
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    consentLabel: string;
    inputError: string;
  };
}

interface TowerProjectionFormState {
  translations?: {
    fullName: string;
    emailLabel: string;
    inputError: string;
    location: string;
    consentLabel: string;
    consentMessage: string;
    confirmRights: string;
    confirmConsents: string;
    confirmDisclaimer: string;
    transferRights: string;
  };
}

interface EventRegistrationFormState {
  translations?: {
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    consentLabel: string;
    inputError: string;
  };
}

const RocheRichtext = 'roche-richtext' as React.ReactType;

const RocheFormEmail = ({ blok, ...rest }: AnyProps): JSX.Element => Default({
  blok: {
    ...blok,
    component: 'roche-form',
    type: 'email',
  },
  ...rest,
});

const RocheFormNewsletter = ({
  blok,
  getComponent,
  ...rest
}: AnyProps): JSX.Element => {
  const { props, slotted } = filterProps(blok);

  const formProps = {
    ...props,
    // eslint-disable-next-line no-underscore-dangle
    ...(blok._uid ? { uid: blok._uid } : {}),
    // eslint-disable-next-line no-underscore-dangle
    ...(blok.id || blok._uid ? { id: blok.id || blok._uid } : {}),
    type: 'signup',
    ...rest,
  };
  const { consent, ...restSlotted } = slotted;
  const children = slottedToComponents(getComponent, restSlotted);
  const selectOptions = [{
    label: props['consent-label'],
    value: 'true',
    required: 'true',
  }];

  const [state, setState] = useState<NewsletterFormState>({});
  useEffect(() => {
    const getTranslations = async (): Promise<void> => {
      const newState: NewsletterFormState = {
        translations: {
          firstNameLabel: await TranslationService.translate('first name'),
          lastNameLabel: await TranslationService.translate('last name'),
          emailLabel: await TranslationService.translate('email'),
          consentLabel: await TranslationService.translate('consent'),
          inputError: await TranslationService.translate('invalid input'),
        },
      };
      setState(newState);
    };
    getTranslations();
  }, []);

  return (
    <roche-form {...formProps}>
      <roche-input error={state.translations?.inputError} type="text" name='firstname' label={state.translations?.firstNameLabel} required='true' />
      <roche-input error={state.translations?.inputError} type="text" name='lastname' label={state.translations?.lastNameLabel} required='true' />
      <roche-input error={state.translations?.inputError} type="email" name='email' label={state.translations?.emailLabel} required='true' />
      {children}
      <roche-select multi-select name='consent' label={state.translations?.consentLabel} options={JSON.stringify(selectOptions)} required='true' />
      {consent[0] && blokToComponent({ blok: slotted.consent[0], getComponent })}
    </roche-form>
  );
};

const RocheFormTowerProjection = ({ blok, getComponent, ...rest }: AnyProps): JSX.Element => {
  const [state, setState] = useState<TowerProjectionFormState>({});
  useEffect(() => {
    const getTranslations = async (): Promise<void> => {
      const newState: TowerProjectionFormState = {
        ...state,
        translations: {
          fullName: await TranslationService.translate('full name'),
          emailLabel: await TranslationService.translate('email'),
          inputError: await TranslationService.translate('invalid input'),
          location: await TranslationService.translate('location'),
          consentLabel: await TranslationService.translate('consent'),
          consentMessage: await TranslationService.translate('click here to confirm that you have read and agree to the terms and conditions'),
          confirmRights: await TranslationService.translate('i confirm that this picture is my work and that I have all rights of use. I herewith waive any right to compensation for the use of my work'),
          confirmConsents: await TranslationService.translate('i confirm that I have obtained all necessary consents and that I am liable for any possible infringements of third party rights'),
          confirmDisclaimer: await TranslationService.translate('i herewith confirm that I have read and understood the privacy notice and legal disclaimer'),
          transferRights: await TranslationService.translate('i herewith transfer irrevocably and free of charge all the rights to the uploaded picture to F. Hoffmann-La Roche Ltd and its affiliate'),
        },
      };
      setState(newState);
    };
    getTranslations();
  }, []);

  const { props, slotted } = filterProps(blok);
  const { consent } = slotted;
  const formProps = {
    ...props,
    // eslint-disable-next-line no-underscore-dangle
    ...(blok._uid ? { uid: blok._uid } : {}),
    // eslint-disable-next-line no-underscore-dangle
    ...(blok.id || blok._uid ? { id: blok.id || blok._uid } : {}),
    type: 'tower-light',
    ...rest,
  };

  const consentOptions = [{
    label: StringService.capitalize(state.translations?.consentMessage || ''),
    value: 'true',
    required: 'true',
  }];

  return (
    <roche-form {...formProps}>
      <roche-image-uploader
        name='image'
        required
      />
      <roche-input
        error={StringService.capitalize(state.translations?.inputError || '')}
        type='text'
        name='fullName'
        label={StringService.capitalize(state.translations?.fullName || '')}
        required
      />
      <roche-input
        error={StringService.capitalize(state.translations?.inputError || '')}
        type='email'
        name='email'
        label={StringService.capitalize(state.translations?.emailLabel || '')}
        required
      />
      <roche-input
        error={StringService.capitalize(state.translations?.inputError || '')}
        type='text'
        name='imageDescription'
        label={StringService.capitalize(state.translations?.location || '')}
        required
      />
      <RocheRichtext>
        <ul>
          <li>{StringService.capitalize(state.translations?.confirmRights || '')}</li>
          <li>{StringService.capitalize(state.translations?.confirmConsents || '')}</li>
          <li>{StringService.capitalize(state.translations?.confirmDisclaimer || '')}</li>
          <li>{StringService.capitalize(state.translations?.transferRights || '')}</li>
        </ul>
      </RocheRichtext>
      <roche-select
        multi-select
        name='consent'
        label={state.translations?.consentLabel}
        options={JSON.stringify(consentOptions)}
        required
      />
      {consent[0] && blokToComponent({ blok: slotted.consent[0], getComponent })}
    </roche-form>
  );
};

const RocheFormEventRegistration = ({
  blok,
  getComponent,
  ...rest
}: AnyProps): JSX.Element => {
  const { props, slotted } = filterProps(blok);

  const formProps = {
    ...props,
    // eslint-disable-next-line no-underscore-dangle
    ...(blok._uid ? { uid: blok._uid } : {}),
    // eslint-disable-next-line no-underscore-dangle
    ...(blok.id || blok._uid ? { id: blok.id || blok._uid } : {}),
    type: 'signup',
    ...rest,
  };

  const { consent, ...restSlotted } = slotted;
  const children = slottedToComponents(getComponent, restSlotted);
  const selectOptions = [{
    label: props['consent-label'],
    value: 'true',
    required: 'true',
  }];

  const [state, setState] = useState<EventRegistrationFormState>({});
  useEffect(() => {
    const getTranslations = async (): Promise<void> => {
      const newState: EventRegistrationFormState = {
        translations: {
          firstNameLabel: await TranslationService.translate('first name'),
          lastNameLabel: await TranslationService.translate('last name'),
          emailLabel: await TranslationService.translate('email'),
          consentLabel: await TranslationService.translate('consent'),
          inputError: await TranslationService.translate('invalid input'),
        },
      };
      setState(newState);
    };
    getTranslations();
  }, []);

  return (
    <roche-form {...formProps}>
      <roche-input error={state.translations?.inputError} type="text" name='firstname' label={state.translations?.firstNameLabel} required='true' />
      <roche-input error={state.translations?.inputError} type="text" name='lastname' label={state.translations?.lastNameLabel} required='true' />
      <roche-input error={state.translations?.inputError} type="email" name='email' label={state.translations?.emailLabel} required='true' />
      {children}
      <roche-select multi-select name='consent' label={state.translations?.consentLabel} options={JSON.stringify(selectOptions)} required='true' />
      {consent[0] && blokToComponent({ blok: slotted.consent[0], getComponent })}
    </roche-form>
  );
};

export const forms = {
  'roche-form-email': RocheFormEmail,
  'roche-form-newsletter': RocheFormNewsletter,
  'roche-form-tower-projection': RocheFormTowerProjection,
  'roche-form-event-registration': RocheFormEventRegistration,
  'roche-form': Default,
};
