import React from 'react';
import { parseAssetSource } from '../props/asset';
import { Props } from '../types';

const Teaser = 'roche-teaser' as React.ReactType;

export const RocheTeaserExternal = ({
  blok, slot,
}: Props): JSX.Element => (
  <Teaser
    article-page-url={blok.url}
    layout={blok.layout}
    teaser-title={blok.teaser_title}
    teaser-description={blok.teaser_description}
    teaser-image={parseAssetSource(blok.teaser_image[0]?.src.filename)}
    slot={slot}
  >
  </Teaser>
);
