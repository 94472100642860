import React, { useEffect, useState } from 'react';
import { TeaserService, ArticleItem, ArticleparticipateItem } from '../../services';
import { ConditionalWrapper } from '../helpers';
import { parseAssetSource } from '../props/asset';
import { Props } from '../types';

const Teaser = 'roche-teaser' as React.ReactType;

export const RocheTeaser = ({
  blok,
  updateHoistedState,
  wrapperTag,
  keyForChildren,
  slot,
}: Props): JSX.Element => {
  const [teaser, setTeaser] = useState<ArticleItem | ArticleparticipateItem>({} as ArticleItem);
  const Wrapper = wrapperTag as React.ReactType;

  useEffect(() => {
    if (!blok.article?.story?.id) {
      return;
    }

    TeaserService.getTeaser(blok.article.story.id)
      .then((result) => {
        setTeaser(result);
        // eslint-disable-next-line no-underscore-dangle
        return updateHoistedState?.(1, 1, blok._uid, result);
      });
  }, []);

  return (
    <ConditionalWrapper
      key={keyForChildren}
      condition={wrapperTag !== undefined && wrapperTag !== ''}
      wrapper={(children: JSX.Element): JSX.Element => <Wrapper slot={slot}>{children}</Wrapper>}
    >
      <Teaser
        article-page-url={`/${teaser.full_slug}`}
        key={keyForChildren}
        layout={blok.layout}
        subcategory={teaser.content?.sub_category}
        tag={teaser.tag_list ? teaser.tag_list[teaser.tag_list.length - 1] : ''}
        teaser-image={parseAssetSource(teaser.content?.teaser_image[0]?.src.filename)}
        teaser-title={teaser.content?.teaser_title}
        teaser-description={teaser.content?.teaser_description}
        event-date={teaser.content?.event_date}
        event-location={teaser.content?.event_location}
        event-city={teaser.content?.event_city}
        loaded={teaser.content !== undefined}
        slot={slot}
      >
      </Teaser>
    </ConditionalWrapper>
  );
};
