import React, { useEffect, useState } from 'react';
import { SbEditableContent } from 'storyblok-react';
import { DomService } from '@rocheglobal/component-library-125/src/services/dom';
import { TranslationService } from '@rocheglobal/component-library-125/src/services/translation';
import { StringService } from '@rocheglobal/component-library-125/src/services/string';
import { Props } from '../types';
import { TeaserSlider } from './roche-teaser-slider';
import { blokToComponent } from '../helpers';
import { TeaserService } from '../../services/teaser';
/* eslint-disable max-len */
/* TODO: Import this from component types, once component-library 2.0 is released
 * import { Layout } from '@rocheglobal/component-library-125/dist/types/components/roche-teaser-highlight/roche-teaser-highlight.types';
 * import { ContainerColor } from '@rocheglobal/component-library-125/dist/types/components/roche-container/roche-container.types';
*/
/* eslint-enable max-len */

const TeaserMosaic = 'roche-teaser-mosaic' as React.ReactType;
const Container = 'roche-container' as React.ReactType;

interface TeaserMosaic extends SbEditableContent {
  button: SbEditableContent[];
  headline: SbEditableContent[];
  teasers: SbEditableContent[];
  teasers_per_slider_page: number;
}
enum ContainerColor {
  TRANSPARENT = 'transparent',
  THEME_AWARE = 'theme-aware',
  GREY = 'grey',
  NEUTRAL = 'neutral'
}

enum Layout {
  VARIANT_A = 'teaser--variant-a',
  VARIANT_B = 'teaser--variant-b',
  VARIANT_C = 'teaser--variant-c'
}

const forceTransparentBackground = (color: ContainerColor, layout: Layout): ContainerColor => {
  if (layout !== Layout.VARIANT_B) {
    return color;
  }
  return ContainerColor.TRANSPARENT;
};

const parseTeaserList = (list: SbEditableContent[]): SbEditableContent[] => {
  const [head, ...tail] = list;
  if (head?.component !== 'roche-teaser-auto' || head.teaser_number <= 0) {
    return list;
  }

  const parsedHead = Array
    .from({ length: head.teaser_number }, (_, index) => index + 1)
    .map((page, index) => ({
      ...head,
      // eslint-disable-next-line no-underscore-dangle
      _uid: `${head._uid}${index}`,
      keyForChildren: index,
      page,
      // eslint-disable-next-line @typescript-eslint/camelcase
      teaser_number: 1,
    }));

  return [...parsedHead, ...tail];
};

const getSyntheticButtons = (
  highlightData: SbEditableContent, translations: Record<string, string>,
): SbEditableContent[] => {
  if (!highlightData || !translations) {
    return [];
  }

  const { content, default_full_slug: slug } = highlightData;
  const { sub_category: category, event_registration_link: registerLink } = content;
  const isDefaultCategory = TeaserService.nonDefaultThemeSubcategories.includes(category);

  return [
    {
      _uid: StringService.generate(),
      id: StringService.generate(),
      component: 'roche-button',
      appearance: 'primary',
      text: isDefaultCategory ? translations.participate : translations.exploreMore,
      href: isDefaultCategory ? registerLink.cachedUrl : slug,
    },
    {
      _uid: StringService.generate(),
      id: StringService.generate(),
      component: 'roche-button',
      appearance: 'tertiary',
      text: `${translations.allOf} ${StringService.capitalize(category)}`,
      href: isDefaultCategory ? slug : DomService.getGlobalConfig().hubPages[category],
    },
  ];
};

export const RocheTeaserMosaic = ({ blok, getComponent }: Props): JSX.Element => {
  const [highlightData, setHighlightData] = useState(undefined);
  const [translations, setTranslations] = useState(undefined);
  const {
    headline,
    teasers_per_slider_page: teasersPerSliderPage,
    button,
    teasers,
    _uid: uid,
    layout,
    mirror_layout: mirrorLayout,
    image_animation_name: imageAnimationName,
    content_animation_name: contentAnimationName,
    color: coloredContainer,
  } = blok as TeaserMosaic;
  const [head, ...tail] = parseTeaserList(teasers);

  useEffect(() => {
    Promise.all([
      TranslationService.translate('participate'),
      TranslationService.translate('explore more'),
      TranslationService.translate('all of'),
    ])
      .then((result: string[]) => setTranslations({
        participate: StringService.capitalize(result[0]),
        exploreMore: StringService.capitalize(result[1]),
        allOf: StringService.capitalize(result[2]),
      }));
  }, []);

  const syntheticHighlightBlok = {
    blok: {
      _uid: `highlight-for-${uid}`,
      component: 'roche-teaser-highlight',
      // eslint-disable-next-line @typescript-eslint/camelcase
      mirror_layout: mirrorLayout,
      layout,
      // eslint-disable-next-line @typescript-eslint/camelcase
      image_animation_name: imageAnimationName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      content_animation_name: contentAnimationName,
      slot: 'mosaic-highlight',
      teaser: head,
      // eslint-disable-next-line @typescript-eslint/camelcase
      slotted_action_buttons: getSyntheticButtons(highlightData, translations),
    },
    updateHoistedState: (
      _: number,
      __: number,
      ___: string,
      result: unknown,
    ): void => setHighlightData(result),
    getComponent,
  };

  const syntheticSliderBlok = {
    blok: {
      _uid: `slider-for-${uid}`,
      component: 'roche-teaser-slider',
      slot: 'mosaic-slider',
      // eslint-disable-next-line @typescript-eslint/camelcase
      teasers_per_slider_page: teasersPerSliderPage,
      teasers: [...tail],
    } as TeaserSlider,
    getComponent,
  };

  return (
    <TeaserMosaic parent-container-color={forceTransparentBackground(coloredContainer, layout)}>
      <Container color={forceTransparentBackground(coloredContainer, layout)} full-width full-height slot='mosaic-highlight'>
        {blokToComponent(syntheticHighlightBlok, 'highlight')}
      </Container>

      {headline.map((element) => blokToComponent({ blok: element, getComponent, slot: 'mosaic-headline' }, 'headline'))}
      {button.map((element) => blokToComponent({ blok: element, getComponent, slot: 'mosaic-button' }, 'button'))}
      {blokToComponent(syntheticSliderBlok, 'slider')}
    </TeaserMosaic>
  );
};
