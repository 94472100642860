import React, { Component, createRef, RefObject } from 'react';
import { SbEditableContent } from 'storyblok-react';
import { Props } from '../types';
import { blokToComponent } from '../helpers';

interface Tracker {
  page: number;
  total: number;
}
interface TeaserWallState {
  activeFilter: string;
  isFetching: boolean;
  teasers: SbEditableContent[];
  tracker: Record<string, Tracker>;
}

const TeaserWall = 'roche-teaser-wall' as React.ReactType;
const Filters = 'roche-filters' as React.ReactType;

export class RocheTeaserWall extends Component<Props, TeaserWallState> {
  elementRef: RefObject<HTMLElement>;

  loadMoreAmount = 8;

  constructor(props) {
    super(props);

    this.state = {
      activeFilter: undefined,
      isFetching: false,
      teasers: this.props.blok.teasers,
      tracker: this.buildTracker(),
    };

    this.loadMore = this.loadMore.bind(this);
    this.handleFiltersUpdate = this.handleFiltersUpdate.bind(this);
    this.elementRef = createRef();
  }

  public componentDidMount(): void {
    this.elementRef.current.addEventListener('rocheLoadMore', this.loadMore);
    this.elementRef.current.addEventListener('rocheFiltersUpdate', this.handleFiltersUpdate);
  }

  public render(): JSX.Element {
    const { getComponent } = this.props;
    const { disable_filters: disableFilters } = this.props.blok;
    const { teasers, isFetching } = this.state;

    return (
      <>
        <TeaserWall
          ref={this.elementRef}
          can-load-more={this.canLoadMore}
          is-fetching={isFetching}
        >
          {!disableFilters
            && <Filters
              filter-by="subcategory"
              can-initialize={!isFetching}
              slot="slotted-filters"
            />
          }
          {teasers
            .map((teaser, index, array) => blokToComponent({
              blok: teaser,
              getComponent,
              updateHoistedState: (rendered: number, total: number, id: string) => {
                const { tracker: oldTracker } = this.state;
                const updatedTracker = {
                  ...oldTracker,
                  [id]: {
                    ...oldTracker[id],
                    // if no item was rendered, it should still count as a page increment
                    page: (oldTracker[id]?.page || 0) + (rendered || 1),
                    total,
                  },
                };

                this.setState({ tracker: updatedTracker, isFetching: array.length - 1 !== index });
              },
              keyForChildren: index,
              slot: 'teasers',
            }, `${index}-teaser`))}
        </TeaserWall>
        {/* Render the next page, so we can predict if load more can be displayed */}
        {this.nextTeasers
          .map((teaser, index, array) => blokToComponent({
            blok: teaser,
            getComponent,
            /*
             * We call render because this.canLoadMore needs to be updated
             * each time we render one of the "next page teasers"
             */
            updateHoistedState: () => this.setState({ isFetching: array.length - 1 !== index }),
            keyForChildren: teaser.page,
            slot: 'next-page-teasers',
          }, `${teaser.page}-next-page-teaser`))}
      </>
    );
  }

  private get canLoadMore(): boolean {
    const { disable_load_more: disableLoadMore } = this.props.blok;

    if (disableLoadMore) {
      return false;
    }

    const { activeFilter } = this.state;

    if (!activeFilter) {
      return this.elementRef.current?.parentElement.querySelectorAll('roche-teaser[slot="next-page-teasers"]').length > 0;
    }

    // next page teasers matching active filter > 0
    return this.elementRef.current.parentElement.querySelectorAll(`roche-teaser[slot="next-page-teasers"][subcategory="${activeFilter}"]`).length > 0;
  }

  private get nextTeasers(): SbEditableContent[] {
    const { tracker, activeFilter } = this.state;
    const arrayLength = Math.ceil(this.loadMoreAmount / this.teaserAutoList.length);

    return this.teaserAutoList
      .filter(({ sub_category: subCategories }) => (
        !activeFilter || subCategories.some((subCategory: string) => subCategory === activeFilter)
      ))
      .map((item) => Array
        .from({ length: arrayLength }, (_, index) => index + 1)
        .map((count) => {
          const { page } = tracker[item.sub_category];
          const nextPage = page + count;

          // eslint-disable-next-line @typescript-eslint/camelcase
          return { ...item, page: nextPage, teaser_number: 1 };
        }))
      .flat(2);
  }

  private loadMore(): void {
    this.setState({ teasers: [...this.state.teasers, ...this.nextTeasers] });
  }

  private handleFiltersUpdate(event: CustomEvent): void {
    this.setState({ activeFilter: event.detail });
  }

  private get teaserAutoList(): SbEditableContent[] {
    return this.props.blok.teasers
      .filter((item: SbEditableContent) => item.component === 'roche-teaser-auto');
  }

  private buildTracker(): Record<string, Tracker> {
    return this.teaserAutoList
      .reduce((accumulator, item): Tracker => {
        // eslint-disable-next-line no-underscore-dangle
        accumulator[item.sub_category] = {
          page: 0,
          total: 0,
        };
        return accumulator as Tracker;
      },
      {});
  }
}
